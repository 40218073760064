import { website } from '@/axios'

export default {
  // acceptMerchent (storeId, payload) {
  //   return marketPlace().patch(`admin/stores/${storeId}`, payload)
  // },
  getVideoDetails (id) {
    return website().get(`admin/galleries/${id}`)
  },
  editVideo (id, payload) {
    return website().put(`admin/galleries/${id}`, payload)
  },
  addVideo (payload) {
    return website().post('admin/galleries/video', payload)
  },
  uploadVideo (payload) {
    return website().post('upload', payload)
  }
}
